import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Box, useTheme, TextField, Select, MenuItem, Typography, Grid, makeStyles, Dialog } from '@material-ui/core'

// Libs
import { FormattedMessage } from 'react-intl'
// import BodyBuilder from './Builder/BodyBuilder'

// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconSearch from '@material-ui/icons/SearchTwoTone'

// Components
import DataTable from 'Views/Components/DataTable'

// Pages
import Header from './Header'
import Footer from './Footer'

// Others
import { projectName, langMessages, axiosSiteData, GetDistinctKeys, GetArticleLink } from 'variables'
import { UserConnectedContext, UtilitiesContext } from 'contexts'

let searchTimeout = 0

const useStyles = makeStyles(theme => ({
    searchBar: {
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('md')]: {
            maxWidth: '60%',
        }
    },
    articleImage: {
        width: 400, height: 'auto'
    },
}))

export function ArticleDialog({ article, open, setOpen }) {

    const classes = useStyles()

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <Box display="flex" px={2} py={4} flexDirection="row" style={{ gap: 40 }} >
                <img src={GetArticleLink(article._Picture)} alt={article._Label} className={classes.articleImage} />
                <Box display="flex" flexDirection={"row"} style={{ gap: 20 }} flexGrow={1}>
                    {/** Names */}
                    <Box display="flex" flexDirection="column" >
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                            <FormattedMessage id="Products.Columns._Label" defaultMessage="Label" />
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                            <FormattedMessage id="Products.Columns._Description" defaultMessage="Description" />
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                            <FormattedMessage id="Products.Columns._Serie" defaultMessage="Serie" />
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                            <FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" />
                        </Typography>
                    </Box>
                    {/** Values */}
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Typography variant="subtitle2" gutterBottom color='textSecondary'>
                            {article._Label}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom color='textSecondary'>
                            {article._Description}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom color='textSecondary'>
                            {article._Serie}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom color='textSecondary'>
                            {article._Unit}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}


// List products
function Listing() {

    const theme = useTheme()
    const classes = useStyles()

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [serie, setSerie] = useState('')
    const [activeProducts, setActiveProducts] = useState([])
    const [series, setSeries] = useState([])
    const { APIError } = useContext(UtilitiesContext)
    const [productsRendered, setProductsRendered] = useState([])
    const [openArticleDialog, setOpenArticleDialog] = useState(false)
    const [currrentArticle, setCurrentArticle] = useState({})

    //
    // ─── EFFECTS ────────────────────────────────────────────────────────────────────
    useEffect(() => {
        axiosSiteData.get(`/products/list/active`)
            .then(response => {
                setActiveProducts(response.data)
                setProductsRendered(response.data)
                setSeries(GetDistinctKeys(response.data, ["_Serie"]))
            })
            .catch(errors => {
                APIError(errors)
            })
    }, [APIError])

    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
        { id: '_Label', numeric: false, label: <FormattedMessage id="Products.Columns._Label" defaultMessage="Label" /> },
        { id: '_Description', numeric: false, label: <FormattedMessage id="Products.Columns._Description" defaultMessage="Description" /> },
        { id: '_Serie', numeric: false, label: <FormattedMessage id="Products.Columns._Serie" defaultMessage="Serie" /> },
        { id: '_Unit', numeric: false, label: <FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" /> }
    ]

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        setSerie('')

        if (toSearch.trim() === "") {

            setProductsRendered(activeProducts)
            return
        }

        setProductsRendered(activeProducts.filter(aProd => (
            [aProd._Label, aProd._Description, aProd._Unit].join(' | ').toLowerCase().includes(toSearch)
        )))

    }

    //
    // ─── FILTER ACTION ──────────────────────────────────────────────────────────────
    const filterAction = event => {
        setSerie(event.target.value)
        setSearch('')

        setProductsRendered(activeProducts.filter(aProd => aProd._Serie === event.target.value))
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            <Grid container spacing={2} style={{ marginBottom: theme.spacing(2) }}>

                <Grid item xs={12} md={8} >
                    {/** Search bar */}
                    <TextField
                        value={search}
                        onChange={event => searchAction(event.target.value)}
                        className={classes.searchBar}
                        variant="outlined"
                        fullWidth
                        label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                        InputProps={{
                            endAdornment: (
                                <IconSearch />
                            ),
                        }}
                    />
                </Grid>

                {/** Series filter*/}
                <Grid item xs={12} md={4} >
                    <Select
                        style={{ backgroundColor: theme.palette.background.paper }}
                        fullWidth
                        variant="outlined"
                        value={serie}
                        onChange={filterAction}
                    >
                        {series.map(elem => (
                            <MenuItem key={elem} value={elem}>{elem}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            {/** DataTable for Products */}
            <Box display="flex" flexDirection="column" height={400} >
                <DataTable
                    columns={columns}
                    rows={productsRendered}
                    order="asc"
                    orderBy="_Label"
                    onRowDoubleClick={(row) => {
                        setCurrentArticle(row)
                        setOpenArticleDialog(true)
                    }}
                />
            </Box>

            {/**Article dIALOG */}
            <ArticleDialog open={openArticleDialog} setOpen={setOpenArticleDialog} article={currrentArticle} />
        </Box>
    )
}

function Articles() {

    const { userConnected } = useContext(UserConnectedContext)
    const theme = useTheme()

    /* const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "scroll",
        download: false,
        print: false,
        selectableRows: 'none',
        rowsPerPage: 100
    } */



    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.Menu.Articles'] || 'Articles'}`

    return (
        <Fragment>
            <Header />
            <Box flexGrow={1} display="flex" flexDirection="column">

                {/** Custom data */}
                {/* <BodyBuilder bodyEntry="b55346c7b975415bbb4ca06d87a2f11e" /> */}

                {/** Products list */}
                <Box display="flex" flexDirection="column" flexGrow={1} py={2} px={4} bgcolor={theme.palette.background.paper} >
                    <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: theme.spacing(2) }}>
                        <FormattedMessage id="Website.Menu.Articles" defaultMessage="Articles" />
                    </Typography>
                    <Listing />
                </Box>
            </Box>

            <Footer />
        </Fragment>
    )
}

export default Articles