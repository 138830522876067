import axios from 'axios'
import { builder } from '@builder.io/react'
// import Lang from './lang.json'




export const axiosSiteData = axios.create()
const siteDataURL = process.env.REACT_APP_SITE_DATA_URL
axiosSiteData.defaults.baseURL = siteDataURL

export const projectName = 'Alumace' 

export const storageLink = 'https://s6v6.c14.e2-5.dev/3cs/Alumace'
export const catalogsStorageRoot = '/catalogs'
export const documentsStorageRoot = '/documents'
export const articlesStorageRoot = '/articles'
export const langLink = `${siteDataURL}/lang`
export const LoginBGLink = 'https://s6v6.c14.e2-5.dev/3cs/Alumace/LoginBG.png'

export function GetCatalogLink(link) {
    return storageLink + catalogsStorageRoot + "/" + link
}

export function GetDocumentLink(link) {
    return storageLink + documentsStorageRoot + "/" + link
}

export function GetArticleLink(link) {
    return storageLink + articlesStorageRoot + "/" + link
}

// Builder io
builder.init('6b7164a9d11a466fb16d321660dd6308')


export let gvUserConnected = {
    name: '',
    email: '',
    company: '',
    phone: '', accountID: '',
    token: '',
    type: 'customer',
    theme: 'light',
    lang: 'pt'
}

export let axiosSiteDataConfig = { headers: { 'user-token': gvUserConnected.token, user: gvUserConnected.email } }

export function setGvUserConnected(userConnected) {
    gvUserConnected = userConnected
    localStorage.setItem('user', JSON.stringify(gvUserConnected))
    axiosSiteDataConfig.headers['user-token'] = gvUserConnected.token
    axiosSiteDataConfig.headers['user'] = gvUserConnected.email
}

export function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
}

export function appendToFileName(filename, append) {
    let extension = getFileExtension(filename)
    let name = filename.slice(0, filename.lastIndexOf("."))
    return name + append + '.' + extension
}

// Language
export let langMessages
export function setLangData(data){
    langMessages = data
}


export function SetLangMessages(messages, afterUploading, onError) {

    // We upload Lang data
    axiosSiteData.post(`/files/lang.json`, messages, axiosSiteDataConfig)
    .then((_) => {

        langMessages = messages
        afterUploading()
    })
    .catch(_ => onError())
}


// Utilties
// Merge strings merges strings while making sure empty strings are replaced by N/A
export function MergeStrings(strings, sep) {
    return strings.map((elem) => elem ? elem : 'N/A').join(sep)
}

export function GroupByKey(list, key) {
    return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
}

export function GroupByKeys(list, keys) {
    return list.reduce((hash, obj) => {
        let index = keys.map((elem) => obj[elem]).join(' | ')
        return {...hash, [index]:( hash[index] || [] ).concat(obj)}
    }, {})
}

export function GroupByKeysIE(list, keys) {

    let result = []
    list.forEach((elem) => {

        let id = keys.map((key) => elem[key]).join(' | ')
        if (!result.some((_, index) => {

            if (result[index].id === id) {
                result[index].data = [...result[index].data, elem]
                return true
            }
            return false
        })) result = [...result, {id: id, data: [elem]}]
    })

    return result
}

export function GetDistinctKeys(list, keys) {

    let result = []
    list.forEach((elem) => {

        let id = keys.map((key) => elem[key]).join(' | ')
        if (!result.some((_, index) => {

            if (result[index] === id) {
                return true
            }
            return false
        })) result = [...result, id]
    })

    return result
}