import React from 'react'
import { Document, Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer'

import Logo from '../../../res/images/logo.png'

// Libs
import { FormattedMessage, IntlProvider } from 'react-intl'

// Others
import { gvUserConnected, langMessages } from '../../../variables'

Font.register({
    family: 'Oswald',
    src: '/Oswald.ttf'
})


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 22,
        marginVertical: 10,
        fontFamily: 'Oswald'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 14,
        fontFamily: 'Oswald'
    },
    text: {
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    textItems: {
        fontSize: 10,
        textAlign: 'justify',
        flexGrow: 1,
        flexBasis: '19%',
        fontFamily: 'Times-Roman'
    },
    textItemsTiny: {
        fontSize: 10,
        textAlign: 'justify',
        flexGrow: 1,
        flexBasis: '10%',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
})


export default function Report({ record }) {
    return (
        <IntlProvider messages={langMessages.messages[gvUserConnected.lang]} locale={gvUserConnected.lang} defaultLocale="en">
            <Document>
                <Page size="A4" style={styles.body}>
                    <Image src={Logo} style={{ height: 50, width: 150 }} />
                    <Text style={styles.title}><FormattedMessage id="Quotations.Report.Title" defaultMessage="Quotation" />
                        &nbsp;{[`#${record._Number}`, record._Reference].join(', ')}
                    </Text>
                    {/** Reference and date */}
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                    <Text style={styles.subtitle}><FormattedMessage id="Orders.Columns._User" defaultMessage="User" />:&nbsp;{[record._User._Name, record._User._Company].join(', ')}</Text>
                        <Text style={styles.subtitle}>
                            <FormattedMessage id="Quotations.Columns._CreatedAt" defaultMessage="Created At" />:&nbsp;{new Date(record._CreatedAt).toLocaleDateString()}
                        </Text>
                    </View>
                    {/** Description */}
                    <View style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                        <Text style={styles.subtitle}><FormattedMessage id="Quotations.Columns._Description" defaultMessage="Description" /></Text>
                        <Text style={styles.text}>{record._Description}</Text>
                    </View>
                    {/** Observations */}
                    <View style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                        <Text style={styles.subtitle}><FormattedMessage id="Quotations.Columns._Observations" defaultMessage="Observations" /></Text>
                        <Text style={styles.text}>{record._Observations}</Text>
                    </View>

                    {/** Items */}
                    {/** Iteams header */}
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20, padding: 5, backgroundColor: '#F9F9F9' }}>
                        <Text style={styles.textItems}><FormattedMessage id="Products.Columns._Label" defaultMessage="Label" /></Text>
                        <Text style={styles.textItemsTiny}><FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" /></Text>
                        <Text style={styles.textItemsTiny}><FormattedMessage id="Quotations.Columns._Quantity" defaultMessage="Quantity" /></Text>
                        <Text style={styles.textItems}><FormattedMessage id="Quotations.Columns._ColorI" defaultMessage="Color(Int)" /></Text>
                        <Text style={styles.textItems}><FormattedMessage id="Quotations.Columns._ColorM" defaultMessage="Color(Mid)" /></Text>
                        <Text style={styles.textItems}><FormattedMessage id="Quotations.Columns._ColorE" defaultMessage="Color(Ext)" /></Text>
                    </View>
                    {record._Items.map(item => (
                        <View key={item._Label} style={{ display: 'flex', flexDirection: 'row', padding: 5 }}>
                            <Text style={styles.textItems}>{item._Label}</Text>
                            <Text style={styles.textItemsTiny}>{item._Unit}</Text>
                            <Text style={styles.textItemsTiny}>{item._Quantity}</Text>
                            <Text style={styles.textItems}>{item._ColorI}</Text>
                            <Text style={styles.textItems}>{item._ColorM}</Text>
                            <Text style={styles.textItems}>{item._ColorE}</Text>
                        </View>
                    ))}
                </Page>
            </Document>
        </IntlProvider>

    )
}