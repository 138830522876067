import React, { useEffect, useState, useContext, Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, Button, DialogContentText, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { ProductsContext } from '../../../contexts'
import { axiosSiteData, axiosSiteDataConfig, GetArticleLink, getFileExtension } from '../../../variables'
import Dialoger from '../../Components/dialoger'
import DataTable from "../../Components/DataTable"
import ContentForm from './ContentFormCustom'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone'

// API config

let searchTimeout = 0

const useStyles = makeStyles(theme => ({
    tableImage: {
        width: "100%", maxWidth: 150, height: 'auto'
    },
    boolTextYes: {
        color: '#00A4FF'
    },
    boolTextNo: {
        color: '#ff3f34'
    }
}))

export function ProductImage({ picture, label, hei }) {

    const classes = useStyles()

    return (
        <>
            <img src={GetArticleLink(picture)} alt={label} className={classes.tableImage} />
        </>
    )
}

// Products module
export default function Products() {

    const classes = useStyles()

    const [content, setContent] = useState('list')      // list | add | edit | delete
    const [selectedRecord, setSelectedRecord] = useState({})
    const [productsRendered, setProductsRendered] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [noMore, setNoMore] = useState(false)

    const { setProducts } = useContext(ProductsContext)

    useEffect(() => {
        axiosSiteData.get(`/products`, axiosSiteDataConfig)
            .then(response => {

                if (response.data.length === 100) {
                    setNoMore(false)
                } else {
                    setHasMore(true)
                }

                setProducts(response.data)
                setProductsRendered(response.data.map((aProduct) => (
                    {
                        ...aProduct,
                        _Picture: <ProductImage picture={aProduct._Picture} label={aProduct._Label} />,
                        _Active: <Typography variant="body1" className={aProduct._Active ? classes.boolTextYes : classes.boolTextNo}>{aProduct._Active ? 'Yes' : 'No'}</Typography>,
                        _HasColor: <Typography variant="body1" className={aProduct._HasColor ? classes.boolTextYes : classes.boolTextNo}>{aProduct._HasColor ? 'Yes' : 'No'}</Typography>,
                        _ColorI: <Typography variant="body1" className={aProduct._ColorI ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorI ? 'Yes' : 'No'}</Typography>,
                        _ColorM: <Typography variant="body1" className={aProduct._ColorM ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorM ? 'Yes' : 'No'}</Typography>,
                        _ColorE: <Typography variant="body1" className={aProduct._ColorE ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorE ? 'Yes' : 'No'}</Typography>
                    }
                )))
            })
    }, [setProducts, content, classes])

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            {/** Conditional list of UI to render */}
            {(content === 'list' || content === 'delete') &&
                <Listing setSelectedRecord={setSelectedRecord} setContent={setContent} productsRendered={productsRendered} setProductsRendered={setProductsRendered}
                    hasMore={hasMore} setHasMore={setHasMore} setNoMore={setNoMore} noMore={noMore}
                />
            }
            {(content === 'add') && <Adding setContent={setContent} />}
            {(content === 'edit') && <Editing selectedRecord={selectedRecord} setContent={setContent} />}
            {content === 'delete' && <Deleting selectedRecord={selectedRecord} setContent={setContent} />}
        </Box>
    )
}

// List products
function Listing({ setSelectedRecord, setContent, productsRendered, setProductsRendered, hasMore, setHasMore, setNoMore, noMore }) {

    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const classes = useStyles()
    const { products, setProducts } = useContext(ProductsContext)

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
        { id: '_Label', numeric: false, label: <FormattedMessage id="Products.Columns._Label" defaultMessage="Label" /> },
        { id: '_Picture', numeric: true, label: <FormattedMessage id="Products.Columns._Picture" defaultMessage="Picture" /> },
        { id: '_Price', numeric: false, label: <FormattedMessage id="Products.Columns._Price" defaultMessage="Price" /> },
        { id: '_Unit', numeric: false, label: <FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" /> },
        { id: '_Active', numeric: false, label: <FormattedMessage id="Products.Columns._Active" defaultMessage="Active" /> },
        { id: '_HasColor', numeric: false, label: <FormattedMessage id="Products.Columns._HasColor" defaultMessage="HasColor" /> },
        { id: '_ColorI', numeric: false, label: <FormattedMessage id="Products.Columns._ColorI" defaultMessage="ColorI" /> },
        { id: '_ColorM', numeric: false, label: <FormattedMessage id="Products.Columns._ColorM" defaultMessage="ColorM" /> },
        { id: '_ColorE', numeric: false, label: <FormattedMessage id="Products.Columns._ColorE" defaultMessage="ColorE" /> },
        { id: '_Serie', numeric: false, label: <FormattedMessage id="Products.Columns._Serie" defaultMessage="Serie" /> },
        { id: 'actions', numeric: false, label: <FormattedMessage id="TableActions" defaultMessage="Actions" /> }
    ]

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        searchTimeout = setTimeout(() => {
            if (toSearch.trim() === "") {
                axiosSiteData.get(`/products`, axiosSiteDataConfig)
                    .then(response => {

                        if (response.data.length === 100) {
                            setNoMore(false)
                        } else {
                            setHasMore(true)
                        }

                        setProducts(response.data)
                        setProductsRendered(response.data.map(aProduct => (
                            {
                                ...aProduct,
                                _Picture: <ProductImage picture={aProduct._Picture} label={aProduct._Label} />,
                                _Active: <Typography variant="body1" className={aProduct._Active ? classes.boolTextYes : classes.boolTextNo}>{aProduct._Active ? 'Yes' : 'No'}</Typography>,
                                _HasColor: <Typography variant="body1" className={aProduct._HasColor ? classes.boolTextYes : classes.boolTextNo}>{aProduct._HasColor ? 'Yes' : 'No'}</Typography>,
                                _ColorI: <Typography variant="body1" className={aProduct._ColorI ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorI ? 'Yes' : 'No'}</Typography>,
                                _ColorM: <Typography variant="body1" className={aProduct._ColorM ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorM ? 'Yes' : 'No'}</Typography>,
                                _ColorE: <Typography variant="body1" className={aProduct._ColorE ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorE ? 'Yes' : 'No'}</Typography>
                            }
                        )))
                        setSearchResults([])
                    })

                return
            }

            axiosSiteData.get(`/products/search/${toSearch}`, axiosSiteDataConfig)
                .then(response => {

                    if (response.data.length === 100) {
                        setNoMore(false)
                    } else {
                        setHasMore(true)
                    }

                    setSearchResults(response.data.map(aProduct => (
                        {
                            ...aProduct,
                            _Picture: <ProductImage picture={aProduct._Picture} label={aProduct._Label} />,
                            _Active: <Typography variant="body1" className={aProduct._Active ? classes.boolTextYes : classes.boolTextNo}>{aProduct._Active ? 'Yes' : 'No'}</Typography>,
                            _HasColor: <Typography variant="body1" className={aProduct._HasColor ? classes.boolTextYes : classes.boolTextNo}>{aProduct._HasColor ? 'Yes' : 'No'}</Typography>,
                            _ColorI: <Typography variant="body1" className={aProduct._ColorI ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorI ? 'Yes' : 'No'}</Typography>,
                            _ColorM: <Typography variant="body1" className={aProduct._ColorM ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorM ? 'Yes' : 'No'}</Typography>,
                            _ColorE: <Typography variant="body1" className={aProduct._ColorE ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorE ? 'Yes' : 'No'}</Typography>
                        }
                    )))
                })
        }, 200)
    }

    //
    // ─── LOAD MORE ACTION ───────────────────────────────────────────────────────────
    const loadMoreAction = () => {

        if (products.length <= 0) return

        if (search.trim() === "") {
            axiosSiteData.get(`/products/${products[products.length - 1]._Label}`, axiosSiteDataConfig)
                .then(response => {

                    if (response.data.length === 100) {
                        setNoMore(false)
                    } else {
                        setHasMore(true)
                    }

                    setProducts(products.concat(response.data))
                    setProductsRendered(productsRendered.concat(response.data.map(aProduct => (
                        {
                            ...aProduct,
                            _Picture: <ProductImage picture={aProduct._Picture} label={aProduct._Label} />,
                            _Active: <Typography variant="body1" className={aProduct._Active ? classes.boolTextYes : classes.boolTextNo}>{aProduct._Active ? 'Yes' : 'No'}</Typography>,
                            _HasColor: <Typography variant="body1" className={aProduct._HasColor ? classes.boolTextYes : classes.boolTextNo}>{aProduct._HasColor ? 'Yes' : 'No'}</Typography>,
                            _ColorI: <Typography variant="body1" className={aProduct._ColorI ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorI ? 'Yes' : 'No'}</Typography>,
                            _ColorM: <Typography variant="body1" className={aProduct._ColorM ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorM ? 'Yes' : 'No'}</Typography>,
                            _ColorE: <Typography variant="body1" className={aProduct._ColorE ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorE ? 'Yes' : 'No'}</Typography>
                        }
                    ))))
                })

            return
        }

        if (searchResults.length <= 0) return
        axiosSiteData.get(`/products/search/${search}/${new Date(searchResults[searchResults.length - 1]._Label).toJSON()}`, axiosSiteDataConfig)
            .then(response => {

                if (response.data.length === 100) {
                    setNoMore(false)
                } else {
                    setHasMore(true)
                }

                setSearchResults(searchResults.concat(response.data.map(aProduct => (
                    {
                        ...aProduct,
                        _Picture: <ProductImage picture={aProduct._Picture} label={aProduct._Label} />,
                        _Active: <Typography variant="body1" className={aProduct._Active ? classes.boolTextYes : classes.boolTextNo}>{aProduct._Active ? 'Yes' : 'No'}</Typography>,
                        _HasColor: <Typography variant="body1" className={aProduct._HasColor ? classes.boolTextYes : classes.boolTextNo}>{aProduct._HasColor ? 'Yes' : 'No'}</Typography>,
                        _ColorI: <Typography variant="body1" className={aProduct._ColorI ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorI ? 'Yes' : 'No'}</Typography>,
                        _ColorM: <Typography variant="body1" className={aProduct._ColorM ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorM ? 'Yes' : 'No'}</Typography>,
                        _ColorE: <Typography variant="body1" className={aProduct._ColorE ? classes.boolTextYes : classes.boolTextNo}>{aProduct._ColorE ? 'Yes' : 'No'}</Typography>
                    }
                ))))

            })

    }

    // ─── Edit Action ───────────────────────────────────────────────────────────────────
    const editAction = (row) => {
        setSelectedRecord(products.filter(aProduct => {
            return aProduct._id === row._id
        })[0])
        setContent('edit')
    }

    // ─── Delete Action ───────────────────────────────────────────────────────────────────
    const deleteAction = (row) => {
        setSelectedRecord(row)
        setContent('delete')
    }

    // Handle infinite scroll
    useEffect(() => {

        if (search.trim() === "") {
            if (productsRendered.length > 0 && (productsRendered.length % 100) === 0 && !noMore) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }
            return
        }

        if (searchResults.length > 0 && (searchResults.length % 100) === 0 && !noMore) {
            setHasMore(true)
        } else {
            setHasMore(false)
        }
    }, [productsRendered, noMore, setHasMore, searchResults, search])

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1} mb={2}>
                {/** Search bar */}
                <TextField
                    value={search}
                    onChange={event => searchAction(event.target.value)}
                    style={{ width: "40%" }}
                    variant="outlined"
                    label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                    InputProps={{
                        endAdornment: (
                            <IconSearch />
                        ),
                    }}
                />

                {/** Button Add */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setContent('add')}
                >
                    <IconAdd /> <FormattedMessage id="TableAdd" defaultMessage="ADD" />
                </Button>
            </Box>

            {/** DataTable for Products */}
            <DataTable
                columns={columns}
                rows={search === '' ? productsRendered : searchResults}
                order="asc"
                orderBy="_Code"
                editAction={editAction}
                deleteAction={deleteAction}
                loadMoreFunc={loadMoreAction} hasMore={hasMore}
            />
        </Box>
    )
}

// Add product
function Adding({ setContent }) {

    const handleSubmit = (record, file) => {

        // We make sure some fields aren't empty
        if (record._Label.trim() === '' || record._Description.trim() === '' || record._Serie.trim() === '' || record._Unit.trim() === '' || record._Price === 0) {
            return false
        }

        // We upload file first
        if (file !== null) {

            const reader = new FileReader()

            reader.onabort = () => { return }
            reader.onerror = () => { return }
            reader.onload = () => {

                // We upload file
                axiosSiteData.post(`/files/articles/product-${uuidv4()}.${getFileExtension(file.name)}`, reader.result, axiosSiteDataConfig)
                    .then((result) => {

                        // We now add the product with the pic link
                        const theRecord = { ...record, _Picture: result.data.result }
                        axiosSiteData.post(`/products`, theRecord, axiosSiteDataConfig)
                            .then(() => {
                                setContent('list')
                            })
                            .catch(errors => console.log(errors))
                    })
                    .catch(errors => console.log(errors))
            }
            reader.readAsArrayBuffer(file)
            return	// 1 file only
        }

        // No pic, we save product directly
        axiosSiteData.post(`/products`, record, axiosSiteDataConfig)
            .then(() => {
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={{
                "_Label": "",
                "_Description": "",
                "_Picture": "",
                "_Unit": "",
                "_Price": 0,
                "Active": false,
                "_HasColor": false,
                "_ColorI": false,
                "_ColorM": false,
                "_ColorE": false,
                "_Serie": ""
            }}
                content="add" setContent={setContent} handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Update product
function Editing({ selectedRecord, setContent }) {

    const handleSubmit = (record, file) => {

        // We make sure some fields aren't empty
        if (record._Label.trim() === '' || record._Description.trim() === '' || record._Serie.trim() === '' || record._Unit.trim() === '' || record._Price === 0) {
            return false
        }

        // We upload file first
        if (file !== null && record._Picture !== selectedRecord._Picture) {

            const reader = new FileReader()

            reader.onabort = () => { return }
            reader.onerror = () => { return }
            reader.onload = () => {

                // We upload file
                axiosSiteData.post(`/files/articles/product-${uuidv4()}.${getFileExtension(file.name)}`, reader.result, axiosSiteDataConfig)
                    .then((result) => {

                        // We now add the product with the pic link
                        const theRecord = { ...record, _Picture: result.data.result }
                        axiosSiteData.put(`/products/${record._id}`, theRecord, axiosSiteDataConfig)
                            .then(() => {
                                setContent('list')
                            })
                            .catch(errors => console.log(errors))
                    })
                    .catch(errors => console.log(errors))
            }
            reader.readAsArrayBuffer(file)
            return	// 1 file only
        }

        // No pic or pic didn;t change, we save product directly
        axiosSiteData.put(`/products/${record._id}`, record, axiosSiteDataConfig)
            .then(_ => setContent('list'))
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={selectedRecord} content="edit" setContent={setContent}
                handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Delete product
function Deleting({ selectedRecord, setContent }) {

    const handleDelete = (setOpen) => {

        axiosSiteData.delete(`/products/${selectedRecord._id}`, axiosSiteDataConfig)
            .then(() => {
                setOpen(false)
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Dialoger
            title={`Product deletion`}
            content={
                <DialogContentText>Would you really like to delete this product ?</DialogContentText>
            }
            onClose={() => {
                setContent("list")
            }}
            onAction={handleDelete}
        />
    )
}
